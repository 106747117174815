import React, { ChangeEvent } from "react";
import ConfigurationService from "../services/ConfigurationService";
import { Form, Button, Message } from "semantic-ui-react";
import LoggingService from "../services/LoggingService";
import ApiService from "../services/ApiService";
import { Card, Input } from "semantic-ui-react";

interface IProps {
    config: ConfigurationService;
    contact: connect.Contact | null;
    isInCall: boolean;

    setCcpVisible: (val: boolean) => void;
}
interface IState {
    visible: boolean;
    activeIndex: number;
    medssId: string;
    medssError: boolean;
    medssMessage: boolean;
}

/**
 * This panel will be displayed below the CCP iFrame
 */
export default class Medss extends React.Component<IProps, IState> {
    private logging: LoggingService = LoggingService.Instance;

    constructor(props: IProps) {
        super(props);
        this.state = {
            visible: true,
            activeIndex: -1,
            medssId: "",
            medssError: false,
            medssMessage: false,
        };
        this.submitMedss = this.submitMedss.bind(this);
        /*this.handleClick = this.handleClick.bind(this);*/
        this.changeMedss = this.changeMedss.bind(this);
        this.handleDismiss = this.handleDismiss.bind(this);
    }

    UNSAFE_componentWillReceiveProps(props: IProps) {
        if (!props.contact) {
            this.setState({ medssId: "" });
        }
    }

    async submitMedss() {
        this.logging.debug("save clicked");
        if (this.props.contact?.getAttributes()) {
            const attributes = {
                MEDSS_ID: this.state.medssId,
            };
            console.log(attributes);
            const api = new ApiService(this.props.config);
            await api.updateContactAttributes(this.props.contact as connect.Contact, attributes);

            this.logging.debug("update attributes completed");
            this.props.setCcpVisible(true);
            this.setState({
                visible: false,
                medssMessage: true,
            });
        }
    }

    async changeMedss(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ medssId: e.target.value });
        if (e.target.value.length > 9) {
            this.setState({ medssError: true });
        } else {
            if (this.state.medssError) {
                this.setState({ medssError: false });
            }
        }
    }
    renderButton() {
        if (this.isButtonVisible()) {
            return (
                <div className="">
                    <Button
                        className="medss-submit"
                        onClick={this.submitMedss}
                        disabled={this.state.medssError || this.state.medssId.length < 9}
                    >
                        Submit
                    </Button>
                </div>
            );
        }
    }

    isButtonVisible() {
        return this.props.contact ? true : false;
    }

    handleDismiss() {
        this.setState({ medssMessage: false });
    }

    render() {
        return (
            <Card className="card-jmb">
                <Card.Content className="header">
                    <Card.Header textAlign="center">Case/Contact Info</Card.Header>
                </Card.Content>
                <Card.Content>
                    <Form>
                        <Form.Field>
                            <label>MEDSS ID</label>
                            <Input
                                placeholder="MEDS"
                                value={this.props.contact ? this.state.medssId : ""}
                                onChange={this.changeMedss}
                                type="number"
                            />
                        </Form.Field>
                        <Message
                            positive
                            header="MEDSS ID Submitted"
                            onDismiss={this.handleDismiss}
                            content="You can still edit and submit the MEDSS ID"
                            hidden={!this.state.medssMessage}
                        />
                        <Message negative style={this.state.medssError ? { display: "block" } : { display: "none" }}>
                            <Message.Header>MEDSS ID error</Message.Header>
                            <p>The MEDSS ID should be a 9 digit number </p>
                        </Message>

                        {this.renderButton()}
                    </Form>
                </Card.Content>
            </Card>
        );
    }
}

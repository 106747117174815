import React, { createRef, ReactNode, RefObject } from "react";
import PauseIcon from "@material-ui/icons/Pause";
import ConfigurationService from "../services/ConfigurationService";
import LoggingService from "../services/LoggingService";
import BuildTime from "./BuildTime";
import { Grid, Card, Image } from "semantic-ui-react";
import "amazon-connect-streams";

import ApiService from "../services/ApiService";
import Medss from "./Medss";

interface IProps {
    config: ConfigurationService;
}
interface IState {
    bannerError: ReactNode;
    isInCall: boolean;
    contact: connect.Contact | null;
    ccpVisible: boolean;
    recentCalls: any;
    queueName: string;
    screenPopUrl: string;
    isRecordingStopped: boolean;
    contactInfo: any;
    ccpToken: string;
    loggedOut: boolean;
}
export default class Ccp extends React.Component<IProps, IState> {
    private logging: LoggingService = LoggingService.Instance;
    private ccpRef: RefObject<HTMLDivElement> = createRef();
    private _api: ApiService = undefined as any;

    constructor(props: IProps) {
        super(props);
        this.state = {
            bannerError: "",
            isInCall: false,
            contact: null,
            ccpVisible: true,
            recentCalls: [],
            queueName: "",
            screenPopUrl: "",
            isRecordingStopped: false,
            contactInfo: {},
            ccpToken: "",
            loggedOut: true,
        };
        this.setCcpVisible = this.setCcpVisible.bind(this);
        this.stopStartRecording = this.stopStartRecording.bind(this);
        this.stopRecording = this.stopRecording.bind(this);
    }

    get api(): ApiService {
        if (!this._api && this.props.config) {
            this._api = new ApiService(this.props.config);
        }
        return this._api;
    }

    getToken(): string {
        let ccpToken = "";
        try {
            ccpToken = this.state.contactInfo.getAttributes().CCP_TOKEN.value;
        } catch (err) {
            ccpToken = "";
        }
        if (ccpToken && ccpToken !== this.state.ccpToken) {
            this.logging.debug("Refreshed ccpToken");
            this.setState({ ccpToken });
        } else {
            this.logging.debug("Reusing ccpToken");
            ccpToken = this.state.ccpToken;
        }
        return ccpToken;
    }

    async stopStartRecording() {
        const ccpToken = this.getToken();
        if (!ccpToken) {
            this.setState({ bannerError: "Error: the CCP_TOKEN attribute is missing" });
            return;
        }

        this.setState({ isRecordingStopped: !this.state.isRecordingStopped });

        if (!this.state.isRecordingStopped) {
            this.api.pauseRecording(this.state.contactInfo);
        } else {
            this.api.resumeRecording(this.state.contactInfo);
        }
    }

    async stopRecording() {
        const ccpToken = this.getToken();
        if (!ccpToken) {
            this.setState({ bannerError: "Error: the CCP_TOKEN attribute is missing" });
            return;
        }

        this.setState({ isRecordingStopped: !this.state.isRecordingStopped });
        this.api.stopRecording(this.state.contactInfo);

        this.logging.debug("stop recording clicked");
        if (this.state.contact?.getAttributes()) {
            const attributes = {
                Recording_Stoped: "true",
            };
            await this.api.updateContactAttributes(this.state.contact as connect.Contact, attributes);

            this.logging.debug("update attributes completed");
        }
    }

    componentDidMount() {
        const calls = JSON.parse(localStorage.getItem("calls") || "[]");
        this.setState({ recentCalls: calls });
        this.setState({ loggedOut: true });
        this.initCCP();
    }

    componentWillUnmount() {
        connect.core.terminate();
    }

    initCCP() {
        this.logging.debug("init ccp called");

        if (connect.core.initialized) {
            this.logging.debug("Already initialized, exiting.");

            return;
        }

        const params: connect.InitCCPOptions = {
            ccpUrl: this.props.config.ccpUrl,
            loginPopup: true,
            loginPopupAutoClose: true,
            region: this.props.config.ccpRegion,
            softphone: {
                allowFramedSoftphone: true,
            },
            loginUrl: this.props.config.samlSsoUrl ? this.props.config.samlSsoUrl : undefined,
        };

        connect.core.initCCP(this.ccpRef.current as HTMLElement, params);

        connect.core.getEventBus().subscribe(connect.EventType.TERMINATED, () => {
            const containerDiv = document.getElementsByClassName("ccp-frame")[0];
            const iframe = containerDiv?.firstElementChild; // assumes there's nothing else in the container
            if (iframe && containerDiv) containerDiv.removeChild(iframe);
            this.setState({ loggedOut: true });
        });

        connect.contact((contact: connect.Contact) => {
            this.logging.debug("contact id " + contact.getContactId());

            if (contact.getType() === connect.ContactType.QUEUE_CALLBACK) {
                contact.onIncoming(() => {
                    this.onIncoming(contact);
                });
            } else if (contact.getType() === connect.ContactType.VOICE) {
                contact.onConnecting(() => {
                    this.onIncoming(contact);
                });
            }

            contact.onConnecting(() => {
                const attributes = contact.getAttributes();
                if (attributes.screenPopUrl) {
                    this.setState({ screenPopUrl: attributes.screenPopUrl.value });
                    window.open(attributes.screenPopUrl.value, "ScreenPop");
                    return;
                }
                console.error("no screenpop url in attributes");
            });

            contact.onConnected(() => {
                this.logging.debug("connected " + contact.getContactId());
                this.setState({
                    isInCall: true,
                    queueName: contact.getQueue().name,
                    contactInfo: contact,
                    isRecordingStopped: false,
                });

                let recentC = this.state.recentCalls;
                if (recentC.length > 0) {
                    const recentCall = recentC[0];
                    if (recentCall.contactId !== contact.getContactId()) {
                        const recentCall: any = {
                            contactId: contact.getContactId(),
                            startTimestamp: new Date().getTime(),
                        };
                        recentC.unshift(recentCall);
                        if (recentC.length > 5) {
                            recentC.pop();
                        }
                        localStorage.setItem("calls", JSON.stringify(recentC));
                        this.setState({ recentCalls: recentC });
                    }
                } else {
                    const recentCall: any = {
                        contactId: contact.getContactId(),
                        startTimestamp: new Date().getTime(),
                    };
                    recentC.unshift(recentCall);
                    if (recentC.length > 5) {
                        recentC.pop();
                    }
                    localStorage.setItem("calls", JSON.stringify(recentC));
                    this.setState({ recentCalls: recentC });
                }
            });

            contact.onEnded(() => {
                this.setState({ isInCall: false, queueName: "" });
                let recentC = this.state.recentCalls;
                if (recentC.length > 0) {
                    const recentCall = recentC[0];
                    if (recentCall.contactId === contact.getContactId() && !recentCall.endTimestamp) {
                        recentCall.endTimestamp = new Date().getTime();
                        recentC.shift();
                        recentC.unshift(recentCall);
                        localStorage.setItem("calls", JSON.stringify(recentC));
                        this.setState({ recentCalls: recentC });
                    }
                }
            });

            this.setState({ contact: contact });
        });

        connect.agent((agent: connect.Agent) => {
            this.setState({ loggedOut: false });

            agent.onStateChange((event: connect.AgentStateChange) => {
                this.logging.debug("agent state change, ", { old: event.oldState, new: event.newState });
                if (event.newState === "Available" && event.newState !== event.oldState) {
                    this.onAgentAvailable();
                }
            });
            agent.onError((a: any) => this.onAgentError(a));
            agent.onSoftphoneError((a: any) => this.onSoftphoneError(a));
        });
    }

    async onIncoming(contact: connect.Contact) {
        this.logging.debug("incoming contact " + contact.getContactId());
    }

    onAgentAvailable() {
        this.setState({
            bannerError: "",
            contact: null,
        });
    }
    onAgentError(a: any) {
        this.logging.error("onAgentError", a);
    }

    link(url: string, label: string) {
        return <a href={url}>{label}</a>;
    }

    onSoftphoneError(a: { errorType: string; errorMessage: string; endPointUrl: string }) {
        this.logging.error("onSoftphoneError", a);
        let error = {};
        if (a.errorType === "unsupported_browser") {
            error = (
                <p>
                    Only the latest 3 versions of Chrome or Firefox are supported. Upgrade your browser to resolve this
                    error.
                </p>
            );
        } else if (a.errorType === "microphone_not_shared") {
            error = (
                <div>
                    <p>
                        The microphone is not accessible, please reload the page and allow microphone access when
                        prompted.
                    </p>
                    <p>
                        Lastly, you can try using {this.link("https://www.mozilla.org/en-US/firefox/new/", "Firefox")}{" "}
                        or {this.link("https://www.google.com/chrome/", "Chrome")}.
                    </p>
                </div>
            );
        } else if (a.errorType === "user_busy_error") {
            this.logging.error(a.errorType + ": " + a.errorMessage);
            error = "";
        } else {
            error = <p>{a.errorType}</p>;
        }
        this.setState({ bannerError: error });
    }

    alert() {
        if (!this.state.bannerError) {
            return null;
        }
        return <div className="error">{this.state.bannerError}</div>;
    }

    setCcpVisible(val: boolean) {
        this.logging.debug(`Setting ccp visibility to ${val}`);
        // this.setState({ ccpVisible: val });
    }

    clickToCall(phoneNumber: string) {
        var endpoint = connect.Endpoint.byPhoneNumber(phoneNumber);
        connect.agent((agent: connect.Agent) => {
            agent.connect(endpoint, {
                success: () => {},
                failure: () => {},
            });
        });
    }

    render() {
        let classes = !this.state.ccpVisible ? "ccp-frame collapsed" : "ccp-frame";

        return (
            <Grid>
                <Grid.Row style={this.state.loggedOut ? { display: "none" } : {}}>
                    <Grid.Column className={this.props.config.isV2() ? "ccp-wrapper v2" : "ccp-wrapper"}>
                        <div className={classes} ref={this.ccpRef} />
                        <div
                            className="actions-panel__btn-row recordingButtons"
                            style={this.state.isInCall ? {} : { display: "none" }}
                        >
                            <div
                                className="actions-panel__btn redButton"
                                style={!this.state.isRecordingStopped ? {} : { display: "none" }}
                                onClick={this.stopRecording}
                            >
                                <PauseIcon />
                                Stop Recording
                            </div>
                        </div>
                        <BuildTime config={this.props.config} />
                        {this.alert()}
                    </Grid.Column>
                    <Grid.Column width="5">
                        <Card className="logo-card">
                            <Image src="logo-mdh-mn-h-blu_rgb.png" wrapped ui={false} />
                        </Card>
                        <Medss
                            config={this.props.config}
                            contact={this.state.contact}
                            setCcpVisible={this.setCcpVisible}
                            isInCall={this.state.isInCall}
                        ></Medss>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={this.state.loggedOut ? { display: "flex" } : { display: "none" }}>
                    <Grid.Column className={this.props.config.isV2() ? "ccp-wrapper v2" : "ccp-wrapper"}>
                        <div className="logout-message">
                            <img className="company-logo" src="./logo-mdh-mn-h-blu_rgb.png"></img>
                            <h3>MDH Call Center</h3>
                            <p>Please wait while we log you in.</p>
                            <p>
                                If the log in window doesn’t appear, click <a href=".">here</a> to log in.{" "}
                            </p>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

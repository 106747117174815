import LoggingService from "./LoggingService";

interface IConfig {
    environment: string;
    buildTime: number;
    ccpUrl: string;
    ccpRegion: string;
    samlSsoUrl: string;


    // CCP_TOKEN attribute must be set to use
    apiUrl: string;
}

export default class ConfigurationService implements IConfig {
    private logger: LoggingService = LoggingService.Instance;
    private cfg: IConfig = null as any;

    public async load() {
        if (!this.cfg) {
            const response = await fetch("config.json");
            this.cfg = await response.json();
            this.logger.debug("Config Loaded");
        }
    }

    get environment(): string {
        return this.cfg.environment;
    }

    get buildTime(): number {
        return this.cfg.buildTime;
    }

    get ccpUrl(): string {
        return this.cfg.ccpUrl;
    }

    get ccpRegion(): string {
        return this.cfg.ccpRegion;
    }

    get samlSsoUrl(): string {
        return this.cfg.samlSsoUrl;
    }

    get apiUrl(): string {
        return this.cfg.apiUrl;
    }

    get pauseRecordingUrl(): string {
        return `${this.apiUrl}/pauseRecording`;
    }

    get resumeRecordingUrl(): string {
        return `${this.apiUrl}/resumeRecording`;
    }

    get stopRecordingUrl(): string {
        return `${this.apiUrl}/stopRecording`;
    }

    isLocal(): boolean {
        return this.environment === "local";
    }

    isV2(): boolean {
        return this.cfg.ccpUrl.includes("ccp-v2");
    }

    get updateAttributesUrl(): string {
        return `${this.apiUrl}/updateAttributes`;
    }
}
